<template>
 <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br />
        <div class="container fluid">
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit/kn21">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/KN21-tre-quarti-rev1-210610-1536x1044.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                KN21
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit/kn30s">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/KN30S-tre-quarti-rev1-210610-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                KN30S
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit/km3011p">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/KM3011P-rev1-210608-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                KN3011P
              </h4>
            </div>
          </div>
          </router-link>
        </div>
          <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit/km3011b">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/KM3011B-REV1-210607-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
              KN3011B
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/km/crimpingunit/km1110">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="https://kmcorporate.com/wp-content/uploads/2021/06/KM1110-rev2-210609-DEF-1536x1044.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               KN1110
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        
        
        </div>
      </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>